import React from 'react'

import { HBox } from 'src/ui/layout'
import { Hero } from 'src/ui/organisms'
import { Body } from 'src/ui/typography'

import { ACTIVITIES_TEXT } from './constants'

type Props = {
  images: TImages
}

export const Kicker: React.FC<Props> = ({ images }) => (
  <Hero
    background={images['activities']}
    title="Увлечения кодеров"
    textHeight={202}
  >
    <HBox />
    <Body>{ACTIVITIES_TEXT}</Body>
  </Hero>
)
