import React from 'react'

import { HBox } from 'src/ui/layout'
import { Hero } from 'src/ui/organisms'
import { Body } from 'src/ui/typography'

import { CUTE_DEADLINE } from './constants'

type Props = {
  images: TImages
}

export const Band: React.FC<Props> = ({ images }) => (
  <Hero background={images['band']} title="Ласковый дедлайн" textHeight={202}>
    <HBox />
    <Body>{CUTE_DEADLINE}</Body>
  </Hero>
)
