import React from 'react'

export const CLUBS_TEXT = (
  <>
    В&nbsp;компании поощряют инициативу не&nbsp;только в&nbsp;профессиональной
    жизни. Часто кодеры открывают свои клубы по&nbsp;интересам и&nbsp;регулярно
    организуют совместные встречи.
    <br />
    <br />
    Каждую неделю мы дискутируем на&nbsp;книжном и&nbsp;киноклубах, играем
    в&nbsp;настолки и&nbsp;видеоигры, танцуем бачату.
  </>
)
