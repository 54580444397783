import React from 'react'

import { HBox } from 'src/ui/layout'
import { HeroDesktopSecondary } from 'src/ui/organisms'
import { Body } from 'src/ui/typography'

import { CUTE_DEADLINE } from './constants'

type Props = {
  images: TImages
}

export const Band: React.FC<Props> = ({ images }) => (
  <HeroDesktopSecondary
    contentPosition="top-left"
    background={images['band']}
    title="Ласковый дедлайн"
    height={724}
    titleSize="h2"
    titleAs="h2"
  >
    <HBox height={24} />
    <Body
      style={{
        maxWidth: '574px',
      }}
    >
      {CUTE_DEADLINE}
    </Body>
  </HeroDesktopSecondary>
)
