import React from 'react'

export const ACTIVITIES_TEXT = (
  <>
    Каждый месяц мы собираемся, чтобы лучше узнать о&nbsp;проектах команд
    и&nbsp;повеселиться. В&nbsp;программе небанальная презентация и&nbsp;конкурс
    лучшего выступления.
    <br />
    <br /> Вне&nbsp;работы играем в&nbsp;любительский футбол и&nbsp;другие
    спортивные игры, нередко занимаемся йогой. Дважды в&nbsp;неделю учим
    английский вместе с&nbsp;классным тьютором.
  </>
)
