import React from 'react'

import { useDimensions } from 'src/ui/dimensions'
import { HBox } from 'src/ui/layout'
import { RootLayout } from 'src/layouts'
import {
  Activities,
  Band,
  Clubs,
  Europe,
  Friday,
  Kicker,
  Rest,
} from 'src/features/fun'
import { Meta } from 'src/features/seo'

import { graphql } from 'gatsby'
import { Query } from 'hr/graphql-types'

type Props = {} & { data: Query }

const Fun: React.FC<Props> = ({ data }) => {
  const images = data.allFile.edges.reduce<TImages>((acc, img) => {
    return {
      ...acc,
      [img.node.name]: img.node.childImageSharp?.fluid as TFluidObject,
    }
  }, {})

  const { isMobile } = useDimensions()

  return (
    <RootLayout>
      <Meta
        title="Работа в IT с удовольствием — KODE"
        description="Ищете работу в IT? Компания KODE приглашает тебя стать частью дружной команды: путешествия с коллегами, спорт и развлечения ✔ Оставляйте заявк и получайте оффер!"
      />
      {isMobile ? <HBox /> : null}
      <Rest images={images} />
      <div id={'travel'}></div>
      {!isMobile ? <HBox height={138} /> : <HBox height={54} />}
      <Europe images={images} />
      {!isMobile ? <HBox height={158} /> : <HBox height={54} />}
      <Activities images={images} />
      {!isMobile ? <HBox height={74} /> : <HBox height={54} />}
      <Clubs images={images} />
      {!isMobile ? <HBox height={74} /> : <HBox height={54} />}
      <Kicker images={images} />
      <div id={'band'}></div>
      {!isMobile ? <HBox height={130} /> : <HBox height={54} />}
      <Band images={images} />

      {!isMobile ? <HBox height={158} /> : <HBox height={54} />}
    </RootLayout>
  )
}

export const query = graphql`
  query {
    allFile(filter: { relativePath: { regex: "/fun//i" } }) {
      edges {
        node {
          name
          relativePath
          childImageSharp {
            fluid(maxWidth: 1900) {
              srcSet
              base64
              src
              aspectRatio
            }
          }
        }
      }
    }
  }
`

export default Fun
