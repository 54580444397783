import React from 'react'

import { breakpoints } from 'src/ui/dimensions'
import { styled } from 'src/ui/theme'
import { HBox, VBox, Wrapper, Col } from 'src/ui/layout'
import { Body, Header } from 'src/ui/typography'

import { Image } from 'src/ui/atoms'
import { TRAVEL } from './constants'

const Title = styled.div`
  display: grid;

  grid-template-columns: 43.9% 52.3%;
  gap: 3.8%;
`

const Grid = styled.div`
  display: grid;

  grid-template-columns: 43.9% 24.2% 24.2%;
  gap: 3.8%;

  margin-bottom: 84px;

  @media (max-width: ${breakpoints.tablet}px) {
    gap: 16px;
    grid-template-columns: 33% 33% 33%;
  }
`

const ReverseGrid = styled.div`
  display: grid;

  grid-template-columns: 24.2% 24.2% 43.9%;
  gap: 3.8%;

  @media (max-width: ${breakpoints.tablet}px) {
    gap: 16px;
    grid-template-columns: 33% 33% 33%;
  }
`

const ImageDescriptions = styled.div`
  padding: 16px 24px;
  background: ${({ theme }) => theme.colors.white.primary};
`

const Year = styled(Body)`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.black.secondary};
`

const GridItem = styled.div`
  position: relative;

  ${ImageDescriptions} {
    position: absolute;
    left: -1px;
    bottom: -1px;
  }
`

type Props = {
  images: TImages
}

export const Europe: React.FC<Props> = ({ images }) => (
  <Wrapper>
    <Title>
      <Col>
        <Header size="h2" as="h2">
          Выездные корпоративы
        </Header>
      </Col>

      <Col>
        <HBox height={8} />
        <Body>{TRAVEL}</Body>
      </Col>
    </Title>

    <HBox height={64} />

    <Grid>
      <GridItem>
        <Image
          height={289}
          fluid={images['moroco']}
          objectPosition="top center"
        />

        <ImageDescriptions>
          <Header size="h4">Марокко</Header>
          <Year>2018</Year>
        </ImageDescriptions>
      </GridItem>
      <GridItem>
        <Image height={289} fluid={images['italy']} />
        <ImageDescriptions>
          <Header size="h4">Италия</Header>
          <Year>2018</Year>
        </ImageDescriptions>
      </GridItem>
      <GridItem>
        <Image
          height={289}
          fluid={images['spain']}
          objectPosition="top right"
        />
        <ImageDescriptions>
          <Header size="h4">Испания</Header>
          <Year>2019</Year>
        </ImageDescriptions>
      </GridItem>
    </Grid>
    <ReverseGrid>
      <GridItem>
        <Image
          height={289}
          fluid={images['dagestan']}
          objectPosition="top center"
        />

        <ImageDescriptions>
          <Header size="h4">Дагестан</Header>
          <Year>2020</Year>
        </ImageDescriptions>
      </GridItem>
      <GridItem>
        <Image height={289} fluid={images['karelia']} />
        <ImageDescriptions>
          <Header size="h4">Карелия</Header>
          <Year>2021</Year>
        </ImageDescriptions>
      </GridItem>
      <GridItem>
        <Image
          height={289}
          fluid={images['minwaters']}
          objectPosition="center"
        />
        <ImageDescriptions>
          <Header size="h4">Минводы</Header>
          <Year>2021</Year>
        </ImageDescriptions>
      </GridItem>
    </ReverseGrid>
  </Wrapper>
)
