import React from 'react'

export const TRAVEL = (
  <>
    Дважды в&nbsp;год команда KODE отправляется в&nbsp;совместное путешествие.
    <br />
    <br />
    Во&nbsp;время открытых границ это преимущественно Европа. Ещё катаемся
    по&nbsp;нетривиальным местам России: были в&nbsp;модном Дагестане,
    сплавлялись на&nbsp;байдарках в&nbsp;Карелии и&nbsp;смотрели
    на&nbsp;заснеженный Эльбрус на&nbsp;Кавказе.
    <br />
    <br /> В&nbsp;течение нескольких дней кодеры активно изучают новую
    территорию и&nbsp;местную кухню, созерцают и&nbsp;всячески набираются сил
    для&nbsp;новых подвигов.
    <br />
    <br />
    <a
      target="_blank"
      href="https://vc.ru/life/303443-korporativnye-puteshestviya-podrobnyy-gaydlayn-po-splocheniyu-it-komandy"
    >
      Подробнее о корпоративных путешествиях читай на VC →
    </a>
  </>
)
