import React from 'react'

import { breakpoints } from 'src/ui/dimensions'
import { styled } from 'src/ui/theme'
import { Wrapper, HBox, Col } from 'src/ui/layout'
import { Image } from 'src/ui/atoms'
import { Body, Header } from 'src/ui/typography'
import { CLUBS_TEXT } from './constants'

const Row = styled.div`
  display: grid;
  grid-template-columns: 43.9% 52.3%;
  gap: 3.8%;

  @media (max-width: ${breakpoints.tablet}px) {
    gap: 16px;
  }

  gap: 50px;
`

const StyledCol = styled(Col)`
  justify-content: center;
`

type Props = {
  images: TImages
}

export const Kicker: React.FC<Props> = ({ images }) => (
  <Wrapper>
    <Row>
      <StyledCol>
        <Header size="h2" as="h2">
          Клубы по интересам
        </Header>
        <HBox />
        <Body>{CLUBS_TEXT}</Body>
      </StyledCol>
      <Image fluid={images['clubs']} height={298} />
    </Row>
  </Wrapper>
)
