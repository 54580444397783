import React from 'react'

import { HBox } from 'src/ui/layout'
import { Hero } from 'src/ui/organisms'
import { Body } from 'src/ui/typography'
import { REST_TEXT } from './constants'

type Props = {
  images: TImages
}

export const Rest: React.FC<Props> = ({ images }) => (
  <Hero
    background={images['rest']}
    title="KODE отдыхает"
    adornment
    label="ВНЕ ОФИСА"
  >
    <HBox />
    <Body>{REST_TEXT}</Body>
  </Hero>
)
