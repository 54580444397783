import React from 'react'
import { useDimensions } from 'src/ui/dimensions'

import { Band as Desc } from './desk'
import { Band as Mob } from './mob'

type Props = {
  images: TImages
}
export const Band: React.FC<Props> = ({ images }) => {
  const { isMobile } = useDimensions()
  return isMobile ? <Mob images={images} /> : <Desc images={images} />
}
