import React from 'react'

import { SwiperSlide } from 'swiper/react/swiper-react.js'

import { HBox, Wrapper } from 'src/ui/layout'
import { Body, Header } from 'src/ui/typography'
import { Image } from 'src/ui/atoms'
import { SwiperSlider } from 'src/ui/organisms'
import { TRAVEL } from './constants'

type Props = {
  images: TImages
}

export const Europe = ({ images }: Props) => (
  <Wrapper>
    <Header size="h4" as="h2">
      Выездные корпоративы
    </Header>
    <HBox />

    <Body>{TRAVEL}</Body>
    <HBox height={24} />
    <SwiperSlider>
      <SwiperSlide>
        <Image
          height={220}
          fluid={images['moroco']}
          objectPosition="top center"
        />

        <HBox />
        <Header size="h5">Марокко, 2018</Header>
      </SwiperSlide>
      <SwiperSlide>
        <Image height={220} fluid={images['italy']} />
        <HBox />
        <Header size="h5">Италия, 2018</Header>
      </SwiperSlide>
      <SwiperSlide>
        <Image
          height={220}
          fluid={images['spain']}
          objectPosition="center right"
        />
        <HBox />
        <Header size="h5">Испания, 2019</Header>
      </SwiperSlide>
      <SwiperSlide>
        <Image
          height={220}
          fluid={images['dagestan']}
          objectPosition="center left"
        />
        <HBox />
        <Header size="h5">Дагестан, 2020</Header>
      </SwiperSlide>
      <SwiperSlide>
        <Image height={220} fluid={images['karelia']} objectPosition="center" />
        <HBox />
        <Header size="h5">Карелия, 2021</Header>
      </SwiperSlide>
      <SwiperSlide>
        <Image
          height={220}
          fluid={images['minwaters']}
          objectPosition="center"
        />
        <HBox />
        <Header size="h5">Минводы, 2021</Header>
      </SwiperSlide>
    </SwiperSlider>
  </Wrapper>
)
