import React from 'react'

import { HBox, Wrapper } from 'src/ui/layout'
import { HeroDesktopSecondary } from 'src/ui/organisms'
import { Body } from 'src/ui/typography'
import { REST_TEXT } from './constants'

type Props = {
  images: TImages
}

export const Rest: React.FC<Props> = ({ images }) => (
  <HeroDesktopSecondary
    contentPosition="bottom-left"
    background={images['rest']}
    title="KODE отдыхает"
    adornment={true}
    titleSize="h2"
    titleAs="h1"
  >
    <HBox height={24} />
    <Body
      style={{
        maxWidth: '640px',
      }}
    >
      {REST_TEXT}
    </Body>
  </HeroDesktopSecondary>
)
