import React from 'react'

export const CUTE_DEADLINE = `
    Карьера нашей гаражной кавер-банды «Ласковый дедлайн» триумфально началась
    в\u00A0новогоднюю ночь 2017\u00A0года. С\u00A0тех пор команда не\u00A0раз
    меняла состав, увлекаясь британским инди, меланхоличным пост-панком,
    мелодичным нью-рейвом и\u00A0даже этническими мотивами. Нынешний состав
    тешит свои стадионные амбиции, репетируя новую программу в студии.
  
`
